.top_left {
 padding: 20px;
}

.dashboard_inner_um {
    background-color: #fff;
    padding: 19px 15px 20px;
    border-radius: 12px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    margin-left: 32px;
    margin-right: 32px;
  }

  .dash_btn_um {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    height: 60px;
    background-color: #2c7be5;
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    margin: 20px;
    /* background-image: linear-gradient(#2c7be5, #fff); */
  }

.button-row {
display: flex;
flex-wrap: wrap;
flex-direction: column;
justify-content: space-between;
}

.button-group {
display: flex;
/* justify-content: center; */
}

.button-group button {
/* margin-right: 10px; */
align-items: center;

}
