.rx_login_wrapper {
  background-color: #4b95d6;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
  padding: 15px 10px;
  .rx_login_inner {
    max-width: 581px;
    width: 100%;
    min-height: 578px;
    background: #ffffff;
    border-radius: 12px;
    padding: 36px 60px;
    margin-bottom: 15px;
    overflow-y: auto;
    .login_header {
      text-align: center;
      .login_logo {
        margin-bottom: 12px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        max-width: 300px;
        margin: 0 auto;
        color: #707070;
      }
      h5 {
        font-size: 20px;
        color: #3283f6;
        line-height: 30px;
        text-transform: uppercase;
        margin-top: 31px;
        margin-bottom: 14px;
        font-weight: 700;
      }
    }
    .rx_form_block {
      .rx_form_field {
        height: 45px;
      }
    }
    .rx_form_checkbox {
      margin-top: -15px;
    }
    .login_btn {
      margin-top: 33px;
    }
  }
  .login_footer {
    text-align: center;
    a {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      line-height: 21px;
      font-weight: 400;
    }
    p {
      display: block;
      font-size: 14px;
      color: #fff;
      line-height: 21px;
      margin: 20px 0 0;
      font-weight: 400;
      a {
        text-decoration: underline;
      }
    }
  }
}
/************ Responsive ************/
@media (max-width: 767px) {
  .rx_login_wrapper {
    .rx_login_inner {
      padding: 36px 20px;
      .login_header {
        p {
          font-size: 18px;
          line-height: 24px;
        }
        .login_logo {
          margin-bottom: 30px;
          img {
            max-width: 180px;
          }
        }
      }
    }
  }
}
