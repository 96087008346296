.icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 999;
}
.calender {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.21' height='27.668' viewBox='0 0 24.21 27.668'%3E%3Cpath id='calendar-days' d='M8.646,1.729V3.459h6.917V1.729a1.729,1.729,0,0,1,3.459,0V3.459h2.594A2.594,2.594,0,0,1,24.21,6.052V8.646H0V6.052A2.594,2.594,0,0,1,2.594,3.459H5.188V1.729a1.729,1.729,0,1,1,3.459,0ZM0,10.376H24.21v14.7a2.6,2.6,0,0,1-2.594,2.594H2.594A2.594,2.594,0,0,1,0,25.074Zm3.459,6.052a.866.866,0,0,0,.865.865H6.052a.867.867,0,0,0,.865-.865V14.7a.867.867,0,0,0-.865-.865H4.323a.866.866,0,0,0-.865.865Zm6.917,0a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V14.7a.867.867,0,0,0-.865-.865H11.24a.867.867,0,0,0-.865.865Zm7.782-2.594a.867.867,0,0,0-.865.865v1.729a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V14.7a.867.867,0,0,0-.865-.865Zm-14.7,9.511a.866.866,0,0,0,.865.865H6.052a.867.867,0,0,0,.865-.865V21.616a.867.867,0,0,0-.865-.865H4.323a.866.866,0,0,0-.865.865Zm7.782-2.594a.867.867,0,0,0-.865.865v1.729a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V21.616a.867.867,0,0,0-.865-.865Zm6.052,2.594a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V21.616a.867.867,0,0,0-.865-.865H18.157a.867.867,0,0,0-.865.865Z' fill='%23b0b0b0'/%3E%3C/svg%3E%0A");
  width: 24.21px;
  height: 27.67px;
  pointer-events: none;
  display: block;
}
.calender_white {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.21' height='27.668' viewBox='0 0 24.21 27.668'%3E%3Cpath id='calendar-days' d='M8.646,1.729V3.459h6.917V1.729a1.729,1.729,0,0,1,3.459,0V3.459h2.594A2.594,2.594,0,0,1,24.21,6.052V8.646H0V6.052A2.594,2.594,0,0,1,2.594,3.459H5.188V1.729a1.729,1.729,0,1,1,3.459,0ZM0,10.376H24.21v14.7a2.6,2.6,0,0,1-2.594,2.594H2.594A2.594,2.594,0,0,1,0,25.074Zm3.459,6.052a.866.866,0,0,0,.865.865H6.052a.867.867,0,0,0,.865-.865V14.7a.867.867,0,0,0-.865-.865H4.323a.866.866,0,0,0-.865.865Zm6.917,0a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V14.7a.867.867,0,0,0-.865-.865H11.24a.867.867,0,0,0-.865.865Zm7.782-2.594a.867.867,0,0,0-.865.865v1.729a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V14.7a.867.867,0,0,0-.865-.865Zm-14.7,9.511a.866.866,0,0,0,.865.865H6.052a.867.867,0,0,0,.865-.865V21.616a.867.867,0,0,0-.865-.865H4.323a.866.866,0,0,0-.865.865Zm7.782-2.594a.867.867,0,0,0-.865.865v1.729a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V21.616a.867.867,0,0,0-.865-.865Zm6.052,2.594a.867.867,0,0,0,.865.865h1.729a.867.867,0,0,0,.865-.865V21.616a.867.867,0,0,0-.865-.865H18.157a.867.867,0,0,0-.865.865Z' fill='%23fff'/%3E%3C/svg%3E%0A");
  width: 24.21px;
  height: 27.67px;
}
.date_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 52px;
  background-color: #f7f7f7;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #ced4da;
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}
