.forgotpwd_wrapper {
  background-color: #4b95d6;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
  padding: 15px 10px;
}

.forgotpwd_wrapper .forgotpwd_inner {
  max-width: 581px;
  width: 100%;
  min-height: 578px;
  background: #ffffff;
  border-radius: 12px;
  padding: 36px 60px;
  margin-bottom: 15px;
  overflow-y: auto;
}

.forgotpwd_wrapper .forgotpwd_inner .forgotpwd_header {
  text-align: center;
}
.forgotpwd_wrapper .forgotpwd_inner .forgotpwd_header .forgotpwd_logo {
  margin-bottom: 12px;
}
.forgotpwd_wrapper .forgotpwd_inner .forgotpwd_header p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  max-width: 300px;
  margin: 0 auto;
  color: #707070;
}
.forgotpwd_wrapper .forgotpwd_inner .forgotpwd_header h5 {
  font-size: 20px;
  color: #3283f6;
  line-height: 30px;
  text-transform: uppercase;
  margin-top: 14px;
  margin-bottom: 14px;
  font-weight: 700;
}

.RegistrationPage {
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.password-info-forgot-pass {
  top: 69%;
}

.registration-input {
  border: 1px solid #e9ecef;
  padding: 0.9em 1em;
  border-radius: 5px;
  width: 100%;
}
.password-icons {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
}

.registration-page {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
  border: 1px solid #e9ecef;
  border-radius: 7px;
  /* box-shadow: 1px 3px 10px #e9ecef; */
  background-color: white;
  align-items: top;
}

.registration-page span {
  color: #adb5bd;
}

#form {
  /* max-width: 320px; */
  /* width: 350px; */
  /* margin: 2em auto; */
  border-radius: 5px;
}

#form > input,
button {
  border: 1px solid #e9ecef;
  padding: 0.9em 1em;
  border-radius: 5px;
}

#form > input:focus {
  outline: none;
}

#form > button {
  background-color: #3283f6;
  color: #e9ecef;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.flex {
  display: flex;
  gap: 1em;
}

.flex-col {
  flex-direction: column;
}

.registration-page .register-col {
  margin: 5%;
  padding: 2%;
  align-items: top;
}

.registration-page .col-2 img {
  width: 480px;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}

@media screen and (min-width: 860px) {
  .registration-page .col-2 img {
    display: initial;
  }
}
