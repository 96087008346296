.registration_wrapper {
  background-color: #4b95d6;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
  padding: 15px 10px;
}
.password-info {
  position: absolute;
  width: 216px;
  height: 128px;
  padding: 12px;
  border-radius: 11px;
  background-color: white;
  border: 1px solid #e9ecefd7;
  right: -239px;
  top: -2px;
}
.password-info-arrow {
  position: absolute;
  left: -24px;
  /* color: #1c1c1c; */
  color: #8080805c;
  font-size: 38px;
  top: 10px;
}
.password-info-text {
  /* color: '#ffffffcf'; */
  color: black;
  margin: 0px;
  font-size: 14px;
}

.registration_wrapper .registration_inner {
  max-width: 581px;
  width: 100%;
  min-height: 578px;
  background: #ffffff;
  border-radius: 12px;
  padding: 36px 60px;
  margin-bottom: 15px;
  overflow-y: auto;
}

.registration_wrapper .registration_inner .registration_header {
  text-align: center;
}
.registration_wrapper
  .registration_inner
  .registration_header
  .registration_logo {
  margin-bottom: 12px;
}
.registration_wrapper .registration_inner .registration_header p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  max-width: 300px;
  margin: 0 auto;
  color: #707070;
}
.registration_wrapper .registration_inner .registration_header h5 {
  font-size: 20px;
  color: #3283f6;
  line-height: 30px;
  text-transform: uppercase;
  margin-top: 14px;
  margin-bottom: 14px;
  font-weight: 700;
}

.RegistrationPage {
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.registration-page {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
  border: 1px solid #e9ecef;
  border-radius: 7px;
  /* box-shadow: 1px 3px 10px #e9ecef; */
  background-color: white;
  align-items: top;
}

.registration-page span {
  color: #adb5bd;
}

#form {
  /* max-width: 320px; */
  /* width: 350px; */
  /* margin: 2em auto; */
  border-radius: 5px;
}

#form > input,
button,
select {
  border: 1px solid #e9ecef;
  padding: 0.9em 1em;
  border-radius: 5px;
}

#form > input:focus {
  outline: none;
}
.input-outline-none {
  border: 0;
  outline: none;
  width: 100%;
  padding-right: 26px;
}
.login-password {
  width: 100%;
  height: 54px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  padding: 0 20px;
  font-size: 14px;
  color: #707070;
  height: 45px;
}

#form > button {
  background-color: #3283f6;
  color: #e9ecef;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.flex {
  display: flex;
  gap: 1em;
}

.flex-col {
  flex-direction: column;
}

.registration-page .register-col {
  margin: 5%;
  padding: 2%;
  align-items: top;
}

.registration-page .col-2 img {
  width: 480px;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}

@media screen and (min-width: 860px) {
  .registration-page .col-2 img {
    display: initial;
  }
}
