* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-family: "Poppins", sans-serif;
  color: #323a46;
  background-color: #f5f6f9;
  padding: 0;
  margin: 0;
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a,
a:hover,
a:focus {
  text-decoration: none;
}
.container {
  max-width: 1663px;
}
.rx_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 144px;
  height: 54px;
  font-size: 22px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #44cf9c;
  color: #fff !important;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: #fff;
  }
}
.btn_cancel {
  color: #00539f !important;
  background-color: #f3f7f9;
}
.disable-anchor {
  pointer-events: none;
}
.disable-tag {
  cursor: not-allowed;
  opacity: 0.85;
}
.dash_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 216px;
  height: 50px;
  background-color: #2c7be5;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: #fff;
  }
}
.login_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  color: #fff;
  background-color: #3283f6;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  &:hover {
    color: #fff;
  }
}
.rx_form_block {
  margin-bottom: 12px;
  .rx_form_field {
    width: 100%;
    height: 54px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    color: #707070;
  }
  .rx_form_label {
    font-size: 14px;
    font-weight: 700;
    color: #707070;
    line-height: 21px;
    margin-bottom: 5px;
  }
  .rx_form_label_2 {
    font-size: 22px;
    font-weight: 500;
    color: #6c757d;
    line-height: 33px;
    margin-bottom: 6px;
    position: relative;
    span {
      font-size: 18px;
    }
    .star {
      position: absolute;
      top: 2px;
      left: -13px;
      color: #d60a0a;
      font-size: 16px !important;
    }
  }

  .custom_select {
    width: 100%;
    height: 55px;
    position: relative;
    border: 1px solid #ced4da;
    border-radius: 4px;
    &::after {
      content: url(../images/icons/caret.svg);
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
      pointer-events: none;
    }
    select {
      appearance: none;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      padding: 0 20px;
      width: 100%;
      background: #fff;
      color: #6c757d;
      border-radius: 4px;
      option {
        color: #6c757d;
      }
    }
  }
}
.rx_form_checkbox {
  label {
    position: relative;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #707070;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 22px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      top: 6px;
      left: 4px;
      width: 16px;
      height: 8px;
      border-bottom: 3px solid #4b95d6;
      border-left: 3px solid #4b95d6;
      transform: rotate(-45deg);
      visibility: hidden;
      opacity: 1;
    }
  }
  input {
    position: absolute;
    left: -99999999px;
    &:checked {
      & ~ label {
        &::after {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
.main_heading {
  h1 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: #323a46;
    margin-bottom: 20px;
  }
}
.sub_heading {
  h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #323a46;
  }
}
.border_bt_none {
  border-bottom: none !important;
}
.mt_33 {
  margin-top: 33px;
}
input[type="date"] {
  min-width: 96%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  appearance: none;
  content: "";
  position: absolute;
  right: 2px;
  top: 2px;
  width: 35px;
  height: 35px;
  z-index: 0;
}
/* Responsive Css */
@media (max-width: 1199px) {
  .rx_form_block {
    .rx_form_label_2 {
      font-size: 18px;
    }
  }
  .main_heading h1 {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 12px;
  }
}
@media (max-width: 768px) {
  .main_heading {
    h1 {
      font-size: 22px;
      line-height: 32px;
    }
  }
  .rx_form_block {
    .custom_select {
      select {
        padding: 0 15px;
      }
    }
    .rx_form_field {
      padding: 0 15px;
    }
  }
}
@media (max-width: 767px) {
  .rx_form_block {
    .rx_form_label_2 {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .dash_btn {
    max-width: 178px;
    height: 46px;
    font-size: 15px;
  }
}
@media (max-width: 575px) {
  .rx_btn {
    min-width: 100px;
    height: 42px;
    font-size: 16px;
  }
}
